<template>
    <div class="ol-dashboard__order">
        <h4 class="ol-dashboard__container-title">Pesanan</h4>
        <div class="ol-dashboard__order-filter">
        <div class="ol-input-box ol-input-box--half">
            <label class="ol-input-label">Urutkan</label>
            <div class="ol-select-box">
            <select class="ol-input ol-input-select" v-model="sortby">
                <option value="terbaru" selected="selected">Terbaru</option>
                <option value="terbesar">Transaksi Terbesar</option>
            </select>
            </div>
        </div>
        <div class="ol-input-box ol-input-box--half">
            <label class="ol-input-label">Status</label>
            <div class="ol-select-box">
            <select class="ol-input ol-input-select" v-model="status">
                <option value="0">Semua</option>
                <option value="1">Belum Dibayar</option>
                <option value="2">Sudah Dibayar</option>
                <option value="3">Proses</option>
                <option value="4">Dikirim</option>
                <option value="5">Komplain</option>
                <option value="6">Selesai</option>
                <option value="91">Pesanan Dibatalkan (manual)</option>
                <option value="92">Pesanan Dibatalkan (expired Pembayaran)</option>

            </select>
            </div>
        </div>
        <div class="ol-input-box ol-input-box--half">
            <label class="ol-input-label">Tanggal</label>
            <div class="ol-select-box">
            <div class="ol-input-ico">
                <input type="text" name="daterange" class="ol-input" id="daterange" value="" placeholder="Masukan Tanggal">
                <span class="ol-fa">

                    <i class="fa fa-calendar"></i>
                </span>
            </div>
            </div>
        </div>
        </div>
        <div class="ol-dashboard__order-search ol-input-ico">
        <div>
            <input type="text" class="ol-input" placeholder="Cari Nama Produk / No. Pesanan" v-model="keyword">
            <i class="ol-fa fas fa-search"></i>
        </div>
        </div>
        <div id="pesanan_content">
            <div v-if="loadingOrder" class="ol-dashboard__order-loop">
                <div class="ol-dashboard__order-list">
                    <div class="ol-dashboard__order-list-item ol-dashboard__order-list-item--head">
                        <SkeletonLoader width="100%" height="70px" />
                    </div>
                    <div class="ol-dashboard__order-list-item ol-dashboard__order-list-item--head">
                        <SkeletonLoader width="100%" height="70px" />
                    </div>
                    <div class="ol-dashboard__order-list-item ol-dashboard__order-list-item--head">
                        <SkeletonLoader width="100%" height="70px" />
                    </div>
                </div>
            </div>
            <div v-else v-for="order in orders" :key="order.id+'-profile-order'" class="ol-dashboard__order-loop">
                <div class="ol-dashboard__order-list">
                    <div class="ol-dashboard__order-list-item ol-dashboard__order-list-item--head">
                        <h4 class="ol-dashboard__order-list-item-id">No. Pesanan : <span>{{order.ordersn}}</span>
                        </h4>
                        <p class="ol-dashboard__order-list-item-status">
                            <span v-if="order.status == 1"> Belum Dibayar</span>
                            <span v-if="order.status == 2"> Sudah Dibayar</span>
                            <span v-if="order.status == 3"> Proses</span>
                            <span v-if="order.status == 4"> Dikirim</span>
                            <span v-if="order.status == 5"> Komplain</span>
                            <span v-if="order.status == 6"> Selesai </span>
                            <span v-if="order.status == 91"> Batal manual</span>
                            <span v-if="order.status == 92"> Batal expired pembayaran</span>
                        </p>
                    </div>
                    <div v-if="order.r_more_transaction_order_details[0]">

                    <div v-for="orderProduct in order.r_more_transaction_order_details[0].r_more_transaction_order_products" :key="orderProduct.id+'-variant-profile-order'" class="ol-dashboard__order-list-item ol-dashboard__order-list-item--body">
                        <div class="ol-dashboard__order-list-item-prd">
                            <router-link :to="'/product/'+orderProduct.r_uni_product_variant.r_uni_product.slug">
                                <figure class="ol-cart-list__body-item-figure">
                                        <img v-if="orderProduct.r_uni_product_variant.r_uni_product_image" :src="orderProduct.r_uni_product_variant.r_uni_product_image.image_url" :alt="orderProduct.r_uni_product_variant.name_long" :title="orderProduct.r_uni_product_variant.name_long" />
                                        <img v-else :src="noImage()" :alt="orderProduct.r_uni_product_variant.name_long" :title="orderProduct.r_uni_product_variant.name_long" />
                                </figure>
                                <div class="ol-cart-list__body-item-desc">
                                    <h3 class="ol-cart-list__body-item-desc-name" :title="orderProduct.r_uni_product_variant.name_long">
                                        <router-link :to="'/product/'+orderProduct.r_uni_product_variant.r_uni_product.slug">{{orderProduct.r_uni_product_variant.name_long}}</router-link>
                                    </h3>
                                    <span v-if="orderProduct.r_more_transaction_order_products_bundling.length < 1" class="ol-cart-list__body-item-desc-type">
                                        {{ orderProduct.r_uni_product_variant.variant ? orderProduct.r_uni_product_variant.variant : orderProduct.r_uni_product_variant.color}}
                                    </span>
                                    <span v-else class="ol-cart-list__body-item-desc-type">
                                        <span v-for="detail in orderProduct.r_more_transaction_order_products_bundling" :key="detail.id+'detail-bundle'"> {{detail.r_uni_product_variant.name_short}} <b>x</b> {{detail.quantity}}<br></span>	
                                    </span>
                                    <small style="color:grey" v-if="orderProduct.is_free == 1"><br>{{orderProduct.free_description}}</small>

                                </div>
                            </router-link>
                        </div>
                        <h4 class="ol-dashboard__order-list-item-price"> {{orderProduct.sub_total_rp}} </h4>
                    </div>
                    </div>

                </div>
                <div class="ol-dashboard__order-status">
                    <div class="ol-dashboard__order-status-action">
                        <router-link v-if="order.status == 91 || order.status == 92" :to="'/profile/order/'+order.ordersn" class="ol-btn ol-btn ol-btn--secondary">Rincian Pembatalan</router-link>
                        <router-link v-if="order.status != 91 && order.status != 92" :to="'/profile/order/'+order.ordersn" class="ol-btn ol-btn ol-btn--secondary">Detail Pesanan</router-link>
                        <a class="ol-btn" @click="toCart(order.r_more_transaction_order_details[0].r_more_transaction_order_products)" >Beli lagi</a>
                        <a v-if="order.status != 1 && order.status != 91 && order.status != 92 && order.note == 'POLKI'" class="ol-btn"  :href="'https://link.morefurniture.id/polki/'+order.ordersn" target="_blank" style="background-color: #394B73;">Lihat Tiket</a>
                    </div>
                    

                    <div class="ol-dashboard__order-status-total">Total Pesanan <h4 class="ol-dashboard__order-list-item-total">{{order.total_rp}}</h4>
                    </div>
                </div>
            </div>

            <div class="ol-paging">
                <nav>
                    <ul class="pagination">
                        <li class="page-item disabled" aria-disabled="true" aria-label="« Previous">
                            <a @click="toPage(ordersInfo.page_active == 1 ? 1 : ordersInfo.page_active - 1)" class="page-link" aria-hidden="true">‹</a>
                        </li>
                        <li  v-for="i in ordersInfo.total_pages" :key="'page-'+i" :class="'page-item page-item-'+i" :data-page="i" aria-current="page">
                            <a @click="toPage(i,filter)" class="page-link">{{i}}</a>
                        </li>
                        <li class="page-item">
                            <a @click="toPage(ordersInfo.page_active == ordersInfo.total_pages ? ordersInfo.page_active : ordersInfo.page_active+1)" class="page-link" rel="next" aria-label="Next »">›</a>
                        </li>
                    </ul>
                </nav>

            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SkeletonLoader from '@/components/loading/skeletonLoader'
export default {
    name:"ProfileOrder",
    components:{
        SkeletonLoader
    },
    computed:{
        ...mapGetters('profile/order', ['orders','ordersInfo']),
    },
    data(){
        return {
            loadingOrder:false,
            keyword:null,
            sortby:"terbaru",
            status:0,
            periode:null,
            filter:{},
            queue:null,
        }
    },
    methods:{
        ...mapActions('profile/order', ['fetchOrders']),
        async toPage(page, payload = {}){
            payload.page = page;
            // if(payload.page != this.ordersInfo.page_active){
                this.loadingOrder = true;
                let result = await this.$store.dispatch("profile/order/fetchOrders",payload);
                this.loadingOrder = false;
                if(!result.success) this.$toast.open({ message: "Gagal mendapatkan data order", type: "error", duration: 5000, });

                $(".page-item").removeClass("active");
                $(".page-item-"+page).addClass("active");
            // }
        },
        async toPageWithQueue(page, payload = {}){
            if(this.queue != null) clearTimeout(this.queue);
            this.queue = setTimeout(async () => {
                this.toPage(page, payload);
            }, 300);
		},
        async toCart(products){
            await this.$store.dispatch("transaction/cart/buyAgain", products);
            window.location.href = '/cart';
            // this.$router.push('/cart');
        },
        jq(){
            let self = this;
            $('#daterange').daterangepicker({
                opens: 'left',
                autoUpdateInput: false,
                locale: { cancelLabel: 'Clear' }
            });

            $('#daterange').on('apply.daterangepicker', function(ev, picker) {
                let value = picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY');
                $(this).val(value);
                self.periode = value;
            });

            $('#daterange').on('cancel.daterangepicker', function(ev, picker) {
                $(this).val('');
                self.periode = null;
            });
        },
    },
    created(){
        this.$store.commit('transaction/checkout/setOrder', null);
    },
    async mounted(){
        this.jq();
        await this.toPage(1);
    },
    watch:{
        keyword: function(value){
            this.filter.q = value;
            // console.log(this.filter);
            this.toPageWithQueue(1,this.filter)
        },
        sortby: function(value){
            this.filter.sortby = value;
            // console.log(this.filter);
            this.toPage(1,this.filter);
        },
        status: function(value){
            this.filter.status = value;
            // console.log(this.filter);
            this.toPage(1,this.filter);
        },
        periode: function(value){
            this.filter.periode = value;
            // console.log(this.filter);
            this.toPage(1,this.filter);
        },
    }
}
</script>

<style>

</style>